import type { AppProps } from 'next/app';
import Layout from 'components/Layout';
import AdsProvider from 'providers/AdProvider';
import { UserProvider } from 'providers/UserProvider';
import { PageAdConfig } from 'interfaces/ads/Ad';
import { PianoPageConfig } from 'services/Piano/entities/PianoPageConfig';
import { QuerylyScript } from 'services/Queryly';
import { HeaderNav } from 'interfaces/navigation/Header';
import Head from 'components/Head';
import { HeadData } from 'utils/metaDataUtils/headData';
import { PageViewDataLayerObject } from 'services/Gtm/entities';
import { useRouter } from 'next/router';
import Loader from 'components/Loader';
import 'styles/Global.css';
import { IterablePageData } from 'services/Iterable/entities';
import Error500 from 'components/Error500';
import { PermutivePageConfig } from 'services/Permutive/entities';
import { BypassPaywall } from 'interfaces/BypassPaywall';
import { FooterProps } from 'components/Footer';
import { ArcherAdsScript } from 'services/ArcherAds';
import { RecommendsFooterProps } from 'components/RecommendsFooter';
import { RedVentureScript } from 'services/RedVenture';
import { BinanceWidgetScript } from 'services/Binance';
import { PushlyScript } from 'services/Pushly';
import { Microsites, ObservedScripts } from 'constants/constants';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { PersonalizationProvider } from 'providers/PersonalizationProvider';
import theme from 'styles/theme';
import GoogleTagManager from 'services/Gtm';
import NoticeProvider from 'providers/NoticeProvider';
import PaywallProvider from 'providers/PaywallProvider';
import 'styles/globalReset.css';
import { Hub } from 'interfaces/Home';
import ErrorBoundary from 'components/ErrorBoundary';
import { UIFragments } from 'utils/log/constants/uiFragments';
import { AppErrorSeverity } from 'utils/log/constants/errorSeverity';
import { useEffect } from 'react';
import logToBackend from 'utils/log/logToBackend';
import { LoggerTags } from 'utils/log/constants/loggerTags';
import scriptObserver from 'utils/miscUtils/scriptsObserver';
import Error500Standalone from './500';

export interface PageProps {
  bypassPaywall?: BypassPaywall;
  dataLayer: PageViewDataLayerObject;
  hasArcherAds?: boolean;
  headData: HeadData;
  headerNav: HeaderNav;
  section?: Hub;
  footer?: FooterProps | RecommendsFooterProps;
  iterablePageViewData: IterablePageData;
  pageAdConfig: PageAdConfig;
  permutivePageConfig: PermutivePageConfig;
  pianoPageConfig: PianoPageConfig;
  redVenture?: boolean;
  widgetCss?: string | null;
}

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  scriptObserver(ObservedScripts);

  useEffect(() => {
    const handleErrors = ({ error }: ErrorEvent) => {
      const err = error as Error;

      logToBackend(LoggerTags.CLIENT_ERROR, err.name, {
        message: err.message,
        traceback: err.stack,
        url: window.location.href,
      });
    };

    const handlePromiseRejections = ({ reason }: PromiseRejectionEvent) => {
      const { message } = reason as Error;
      const traceback = (reason as Error).stack;

      const reasonIsError = typeof message === 'string' && typeof traceback === 'string';

      /* eslint-disable indent */
      logToBackend(LoggerTags.CLIENT_ERROR, 'UnhandledRejection', {
        ...(reasonIsError ?
          {
              message,
              traceback,
            } :
          {}),
        url: window.location.href,
      });
      /* eslint-enable indent */
    };

    window.addEventListener('error', handleErrors);
    window.addEventListener('unhandledrejection', handlePromiseRejections);

    return () => {
      window.removeEventListener('error', handleErrors);
      window.removeEventListener('unhandledrejection', handlePromiseRejections);
    };
  }, []);

  // Handle standalone pages
  const isStandalonePage = router.pathname === '/500';

  if (isStandalonePage) {
    return (
      <ThemeProvider theme={theme('')}>
        <Component />
      </ThemeProvider>
    );
  }

  // Handle loadder
  if (router.isFallback) {
    return <Loader />;
  }

  // Standard pages follow
  const {
    bypassPaywall,
    dataLayer,
    hasArcherAds,
    headData: {
      authors,
      databaseId,
      dateGmt,
      description,
      faqSchema,
      hasVanityUrl,
      image,
      imageTwitter,
      jsonLdSchema,
      keywords,
      link,
      rssFeedLink,
      socialDescription,
      socialTitle,
      title,
      pageType,
      premiumCategory,
      primarySection,
      siteName,
      canonicalUrl,
      tagNames,
      widgetCss,
      prefixedDatabaseId,
      noIndex,
    },
    section,
    footer,
    iterablePageViewData,
    headerNav,
    pageAdConfig,
    permutivePageConfig,
    pianoPageConfig,
    redVenture,
    ...rest
  } = pageProps as PageProps;
  const leaderboard = pageAdConfig ?
    pageAdConfig.slots.filter((slot) => slot.placementName === 'Leaderboard').length > 0 :
    false;

  const useBinanceWidget = Microsites.Crypto === headerNav?.subDomain;
  const usePushly = process.env.FEATURE_FLAG_PUSHLY;

  return (
    <ErrorBoundary
      fragment={UIFragments.MAIN_LAYOUT}
      severity={AppErrorSeverity.CRITICAL}
      fallback={
        <ThemeProvider theme={theme('')}>
          <Error500Standalone />
        </ThemeProvider>
      }
    >
      <UserProvider {...{ bypassPaywall, iterablePageViewData, pianoPageConfig }}>
        <Head
          {...{
            authors,
            canonicalUrl,
            databaseId,
            dateGmt,
            description,
            faqSchema,
            hasVanityUrl,
            image,
            imageTwitter,
            jsonLdSchema,
            keywords,
            link,
            noIndex,
            pageAdConfig,
            pageType,
            pianoPageConfig,
            prefixedDatabaseId,
            premiumCategory,
            primarySection,
            redVenture,
            rssFeedLink,
            siteName,
            socialDescription,
            socialTitle,
            subDomain: headerNav?.subDomain,
            tagNames,
            title,
            useBinanceWidget,
            widgetCss,
          }}
        />

        <ThemeProvider theme={theme(headerNav?.subDomain)}>
          <PersonalizationProvider>
            <GoogleTagManager {...{ dataLayer }}>
              <PaywallProvider {...{ pianoPageConfig }}>
                <AdsProvider {...{ pageAdConfig, permutivePageConfig }}>
                  <StyleSheetManager enableVendorPrefixes>
                    <NoticeProvider>
                      <Layout
                        {...{
                          currentAuthors: authors,
                          currentSection: primarySection,
                          currentTags: tagNames,
                          footer,
                          hasVanityUrl,
                          headerNav,
                          leaderboard,
                          pageType,
                          section,
                        }}
                      >
                        <ErrorBoundary
                          fragment={UIFragments.PAGE_CONTENT}
                          severity={AppErrorSeverity.CRITICAL}
                          fallback={<Error500 />}
                        >
                          <Component
                            section={section}
                            {...rest}
                          />
                        </ErrorBoundary>
                      </Layout>
                    </NoticeProvider>
                  </StyleSheetManager>
                </AdsProvider>
              </PaywallProvider>
              <QuerylyScript />
              {hasArcherAds && <ArcherAdsScript />}
              {redVenture && <RedVentureScript />}
              {useBinanceWidget && <BinanceWidgetScript />}
              {usePushly && <PushlyScript />}
            </GoogleTagManager>
          </PersonalizationProvider>
        </ThemeProvider>
      </UserProvider>
    </ErrorBoundary>
  );
};

export default App;
