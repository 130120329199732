/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useContext } from 'react';
import { UserContext } from 'providers/UserProvider';
import { AD_TAGS } from 'components/PaidPlacement';
import Script from 'next/script';

export interface StnVideoProps {
  dataCy?: string;
  videoId: string;
  adTags?: {
    name: string;
    slug: string;
  }[];
}

const StnVideo = ({ videoId, adTags, dataCy = '' }: StnVideoProps) => {
  const { hasConsent } = useContext(UserContext);
  const updateStnCid = adTags?.find((tag) => tag.name === AD_TAGS.NO_VIDEO_ADS);
  const updatedVideoId = updateStnCid ? videoId.replace(/-14497$/g, '-16481') : videoId;

  return hasConsent ? (
    <>
      <Script
        src={`https://embed.sendtonews.com/player3/embedcode.js?SC=${updatedVideoId}`}
        strategy='afterInteractive'
      />
      <div
        data-cy={dataCy}
        className={`stn-video-player s2nPlayer k-${updatedVideoId}`}
        data-type='float'
      />
    </>
  ) : null;
};

export default StnVideo;
