import styled, { css, keyframes } from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

const dropdown = keyframes`
    0% { transform: translateY(-150px) }
    100% { transform: translateY(0) }
`;

export const Wrapper = styled.div<{
  $isMobile?: boolean;
  $isTablet?: boolean;
  $isSimple: boolean;
  $showNewTopicNav: boolean;
  theme: Theme;
}>`
  animation: ${(props) => (props.$isSimple ? css`${dropdown} 500ms forwards` : '')};
  background: ${getColor('surfaceWhite')};
  will-change: transform;
  position: ${(props) =>
    (!props.$showNewTopicNav || props.$isMobile || props.$isTablet || props.$isSimple ? 'sticky' : 'relative')};
  top: var(--cc-sbillboard-min-height, var(--ld-height, 0));
  transform: translateZ(0);
  z-index: 9999;
  box-shadow: 0px 15px 30px 0px ${getColor('surfaceBlack')}10;
  height: auto;
  isolation: isolate;
`;

export const SimpleNavWrapper = styled.div<{ theme: Theme; $isSimple: boolean }>`
  display: ${(props) => (props.$isSimple ? 'flex' : '')};
  box-shadow: 0px 10px 10px 0px ${({ $isSimple }) => ($isSimple ? getColor('textPrimary') : '')}0D;
`;

export const NavigationMobileContent = styled.nav`
  display: flex;
  align-items: center;
  padding: 16px 8px;
  height: 54px;
  position: relative;

  .logo {
    height: 24px;
    cursor: pointer;
  }

  .crypto-logo {
    height: 24px;
    width: 170px;
  }

  .well-logo {
    height: 24px;
    width: 178px;
    cursor: pointer;
  }

  .recommends-logo {
    height: 16px;
    width: 200px;
    cursor: pointer;
  }
    
  .education-logo {
    height: 16px;
    cursor: pointer;
  }
  .subscribe-cta-mobile {
      letter-spacing: 2px;
      font-family: ${font.graphikCond};
      font-weight: bold;
      position: absolute;
      right: 20px;
      line-height: 16px;
      font-size: 14px;
      
    }
`;

export const NavigationContent = styled.header<{ $isSimple: boolean; $isRecommendsDisabled: boolean }>`
  margin: ${(props) => (props.$isSimple ? '' : '0 auto')};
  height: 68px;
  display: ${(props) => (props.$isSimple ? 'flex' : 'grid')};
  grid-auto-flow: column;
  align-items: center;
  padding: 0;
  font-family: ${font.graphikCond};
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;

  @media (${breakpoint.mdMin}) {
    padding: 0 24px;
  }

  @media (${breakpoint.lgMin}) {
    padding: 0 48px;
  }

  @media (${breakpoint.xxlMin}) {
    max-width: 1440px;
  }

  &.own, &.europe, &.asia {
    grid-template-columns: 1fr 140px 1fr;
  }
  
  &.crypto {
    grid-template-columns: 1fr 307px 1fr;
  }
  
  &.well {
    grid-template-columns: 1fr 264px 1fr;
  }
  
  &.recommends, &.education {
    grid-template-columns: ${(props) => (props.$isRecommendsDisabled ? '1fr 140px 1fr' : '1fr 380px 1fr')} ;
  }
    
  @media (${breakpoint.lgMin}) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const NavigationLeft = styled.div`
  display: flex;
`;

export const NavigationSearchWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  > label {
    color: ${getColor('textPrimary')};
    cursor: pointer;
    margin: auto;
  }
`;

export const NavigationCenter = styled.div<{ $isSimple: boolean }>`
  text-align: center;
  display: ${(props) => (props.$isSimple ? 'flex' : '')};
  
  svg {
    height: ${(props) => (props.$isSimple ? '16px' : '32px')};
  }
  
  .recommends-logo {
    width: ${(props) => (props.$isSimple ? '224px' : '380px')};
  }
`;

export const NavigationRight = styled.div<{ theme: Theme }>`
  display: flex;
  justify-self: end;
  position: relative;
  min-height: 24px;

  .account-placeholder {
    position: absolute;
    margin: auto;
    top: 0; bottom: 0; right: 0;
  }

  &.crypto {
    @media (${breakpoint.lgMax}) {
      display: none;
    }
  }
  &.well {
    display: none;
  }

  .account-menu {
    margin: auto;
    &.well {
      display: none;
    }
  }

  .subscribe-link {

    @media (${breakpoint.mdMin}) {
      padding: 8px 16px;
      margin-left: 24px;
    }
    @media (${breakpoint.lgMin}) {
      margin-left: 40px;
      font-size: 20px;
    }
  }

  .sign-in-button {
    margin: 0;
    margin-left: 16px;
    text-decoration: none;
    color: ${getColor('neutralBlack')};

    &:hover {
      text-decoration: none;
    }

    &:active {
      color: ${getColor('neutralDarkGray')};
    }

    @media (${breakpoint.mdMin}) {
      margin-right: 16px;
    }
      
    @media (${breakpoint.lgMin}) {
      font-size: 20px;
      margin-left: 40px;
      margin-right: 0;
    }
  }
`;

export const NavigationPlaceholder = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 8px;
  height: 54px;

  .menu-placeholder {
    margin-right: 24px;
  }

  @media (${breakpoint.mdMin}) {
    padding: 0px 24px;
    height: 68px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 380px 1fr;
  }

  @media (${breakpoint.xlMin}) {
    padding: 0px 48px;
    margin: 0 auto;
  }

   @media (${breakpoint.xxlMin}) {
    max-width: 1440px;
  }
`;
