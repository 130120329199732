/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useState } from 'react';
import { PageAdConfig } from 'interfaces/ads/Ad';
import { PermutivePageConfig } from 'services/Permutive/entities';
import Permutive from 'services/Permutive';
import { UserContext } from 'providers/UserProvider';
import Bombora from 'services/Bombora';
import { AdContext, defaultAdContext } from 'services/Ad';
import Script from 'next/script';
import AdManager from './AdManager';

interface AdProviderProps {
  children: JSX.Element;
  pageAdConfig?: PageAdConfig;
  permutivePageConfig: PermutivePageConfig;
}

/**
 * If no consent is give, do not load ads.
 * Load Permutive before the ad library.
 */
const AdProvider: React.FC<AdProviderProps> = ({ children, pageAdConfig, permutivePageConfig }) => {
  const { hasConsent } = useContext(UserContext);
  const [adContextValue, setAdContextValue] = useState(defaultAdContext);
  const { hasLoggedIn } = useContext(UserContext);
  const renderAdScripts = hasConsent && pageAdConfig;

  useEffect(() => {
    if (hasConsent === false) {
      setAdContextValue(defaultAdContext);
    }
  }, [hasConsent]);

  if (pageAdConfig) {
    pageAdConfig.loginStatus = hasLoggedIn ? 'logged-in' : 'guest';
  }

  const arcSpanPageConfig = {
    ...pageAdConfig,
    ...permutivePageConfig,
  };

  return (
    <>
      {renderAdScripts && (
        <>
          <Bombora />
          <AdManager
            pageAdConfig={pageAdConfig}
            setAdContextValue={setAdContextValue}
          />
        </>
      )}
      <AdContext.Provider value={adContextValue}>{children}</AdContext.Provider>
      {renderAdScripts && (
        <Permutive
          permutivePageConfig={permutivePageConfig}
          pageAdConfig={pageAdConfig}
        />
      )}
      {renderAdScripts && process?.env?.ARCSPAN_ORGANIZATION_ID?.length && (
        <Script
          id='arcSpan'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `
                (function() {
                  window.arcSpanPageConfig = ${JSON.stringify(arcSpanPageConfig)};
                  var s = document.createElement('script'), el = document.getElementsByTagName('script')[0];
                  s.async = true;
                  var _a = (new URL(location.href));
                  var _p = (_a.origin.replace('staging.', 'www.')) + _a.pathname.replace(/\\/$/, '');
                  s.type = 'text/javascript';
                  s.src = 'https://${process.env.ARCSPAN_ORGANIZATION_ID}.p7cloud.net/as1.js?uri=' + _p;
                  el.parentNode.insertBefore(s, el);
                })();
              `,
          }}
        />
      )}
    </>
  );
};

export default AdProvider;
