/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { getLocalIsoDateTime } from 'utils/dateUtils';
import { Author } from 'interfaces/content/Authors';
import { AuthorPageData } from 'interfaces/content/AuthorPageData';
import { Page, PageType, PersonPage, Post } from 'interfaces/content/articles/Post';
import { Franchise, FranchiseListItem, FranchiseListItemPreview, FranchisePage } from 'interfaces/content/Ranking';
import { Company } from 'interfaces/content/Company';
import { TermPage } from 'interfaces/content/TermPage';
import { Homepage, Hub, SecondaryHomepage } from 'interfaces/Home';
import { Article } from 'interfaces/content/articles/Articles';
import { SimplePage } from 'interfaces/content/articles/SimplePage';
import { PageAdConfig } from 'interfaces/ads/Ad';
import { citiesRankingListSlugs } from 'constants/constants';
import { EduRankingPageMeta } from 'interfaces/content/EducationRanking';
import {
  AuthorPageSchema,
  AuthorSchema,
  BreadcrumbsListElement,
  JsonLdSchema,
  ListSchema,
  SiteLinksSearchBoxSchema,
} from 'interfaces/metaDataUtils/jsonLdSchema';

export function addStnMetaSchema(schema: string, pageAdConfig: PageAdConfig): string {
  const schemaObj = JSON.parse(schema) as JsonLdSchema[] | JsonLdSchema;

  function ParseStnKVP(keypairs: string[]): string[] {
    return keypairs.map((keypair) => keypair.replaceAll('-', '').replaceAll(' ', '').toLowerCase());
  }

  if (Array.isArray(schemaObj) && schemaObj[0].stnMeta) {
    schemaObj[0].stnMeta.bmb = 'bmb';
    schemaObj[0].stnMeta.cid = pageAdConfig.cid;
    schemaObj[0].stnMeta.ch = pageAdConfig.ch;
    schemaObj[0].stnMeta.topics = ParseStnKVP(pageAdConfig.topics);
    schemaObj[0].stnMeta.tags = ParseStnKVP(pageAdConfig.tags);
    schemaObj[0].stnMeta.franchise = 'franchise';
    schemaObj[0].stnMeta.campaign = 'campaign';
    schemaObj[0].stnMeta.permutive = [];
  } else if (!Array.isArray(schemaObj) && schemaObj.stnMeta) {
    schemaObj.stnMeta.bmb = 'bmb';
    schemaObj.stnMeta.cid = pageAdConfig.cid;
    schemaObj.stnMeta.ch = pageAdConfig.ch;
    schemaObj.stnMeta.topics = ParseStnKVP(pageAdConfig.topics);
    schemaObj.stnMeta.tags = ParseStnKVP(pageAdConfig.tags);
    schemaObj.stnMeta.franchise = 'franchise';
    schemaObj.stnMeta.campaign = 'campaign';
    schemaObj.stnMeta.permutive = [];
  }

  return JSON.stringify(schemaObj);
}

function setDefaultStnMetaSchema(schema: Partial<JsonLdSchema>) {
  schema.stnMeta = {
    bmb: 'bmb',
    campaign: 'campaign',
    ch: 'ch',
    cid: 'cid',
    franchise: 'franchise',
    permutive: [],
    tags: [],
    topics: [],
  };

  return schema;
}

function stnMetaSchemaContext(hasStnVideo: boolean) {
  if (hasStnVideo) {
    return [
      'https://schema.org',
      {
        stnMeta: {
          '@id': 'https://stnvideo.com',
          '@type': '@json',
        },
      },
    ];
  }
  return ['https://schema.org'];
}

function setGlobalSchema(schema: Partial<JsonLdSchema>, hasStnVideo: boolean) {
  schema['@type'] = 'NewsArticle';
  schema['@context'] = stnMetaSchemaContext(hasStnVideo);
  schema.publisher = {
    '@type': 'NewsMediaOrganization',
    logo: {
      '@type': 'ImageObject',
      url: 'https://fortune.com/static/media/logo.png',
    },
    name: 'Fortune',
  };

  return schema;
}

function setPublisherSchema() {
  return {
    '@type': 'NewsMediaOrganization',
    address: {
      '@type': 'PostalAddress',
      addressCountry: {
        '@type': 'Country',
        name: 'US',
      },
      addressLocality: 'New York',
      addressRegion: 'NY',
      postalCode: '10038',
      streetAddress: '40 Fulton Street',
    },
    brand: {
      '@type': 'Brand',
      name: 'Fortune',
    },
    founder: {
      '@type': 'Person',
      name: 'Henry Robinson Luce',
    },
    foundingDate: '1929-02',
    legalName: 'Fortune Media (USA) Corporation',
    logo: {
      '@type': 'ImageObject',
      url: 'https://fortune.com/static/media/logo.png',
    },
    name: 'Fortune',
    publishingPrinciples: 'https://fortune.com/about-us/',
    sameAs: [
      'https://en.wikipedia.org/wiki/Fortune_(magazine)',
      'https://www.facebook.com/FortuneMagazine/',
      'https://www.linkedin.com/company/fortune-magazine/',
      'https://www.instagram.com/fortunemag/',
      'https://www.pinterest.com/fortunemagazine/',
      'https://twitter.com/FortuneMagazine/',
    ],
    url: 'https://fortune.com/',
  };
}

function setAuthorPageSchema(authorPage: AuthorPageData, schema: Partial<AuthorPageSchema>) {
  const { authorUrl, description, displayName, sameAs } = authorPage;

  if (displayName) {
    schema['@type'] = 'ProfilePage';
    schema['@id'] = authorUrl;

    const mainEntityObj: Partial<AuthorSchema> = {
      '@type': 'Person',
      name: displayName,
    };

    if (authorUrl) {
      mainEntityObj.url = authorUrl;
    }
    if (description) {
      mainEntityObj.description = description;
    }
    if (sameAs && sameAs.length > 0) {
      mainEntityObj.sameAs = sameAs;
    }

    schema.mainEntity = mainEntityObj;
    schema.publisher = setPublisherSchema();
  }

  return schema as JsonLdSchema;
}

function setItemListSchema(listPage: FranchisePage) {
  let type: string;

  if (listPage.listType === 'list') {
    type = 'Organization';
  } else {
    type = listPage.listType === 'grid' && citiesRankingListSlugs.includes(listPage.slug) ? 'City' : 'Person';
  }

  const schema = Object.values(listPage.lists as { title?: string; items: FranchiseListItemPreview[] }[])
    .map((entry) =>
      entry.items.map((item: any) => ({
        '@type': 'ListItem',
        item: {
          '@id': item.url,
          '@type': type,
          name: item.title,
        },
        position: item.rank,
      })))
    .flat();

  return schema;
}

function setAuthorSchema(authors: Author[]) {
  return authors.map((author: Author) => {
    const { type, name, url, email, image, sameAs } = author;
    const schema: Partial<AuthorSchema> = {
      '@type': type || 'Person',
      name,
      url,
    };
    if (email) {
      schema.email = email;
    }
    if (image) {
      schema.image = image;
    }
    if (sameAs !== 'null' && sameAs !== '') {
      const schemaObject = JSON.parse(sameAs) as JSON;
      schema.sameAs = Object.values(schemaObject);
    }
    return schema as AuthorSchema;
  });
}

function setCompanySchema(company: Company, schema: Partial<JsonLdSchema>, hasStnVideo: boolean) {
  schema['@type'] = 'NewsArticle';
  schema['@context'] = stnMetaSchemaContext(hasStnVideo);
  schema.headline = company.title;
  schema.url = company.permalink;
  schema.mainEntityOfPage = company.permalink;
  schema.dateModified = getLocalIsoDateTime(company.modifiedGmt);
  schema.datePublished = getLocalIsoDateTime(company.dateGmt);
  schema.identifier = company.databaseId;
  schema.thumbnailUrl = company.image?.mediaItemUrl;
  schema.image = company.image?.mediaItemUrl;
  schema.author = setAuthorSchema(company.authors);
  schema.articleSection = 'Uncategorized';
  schema.description = company.description;

  return schema;
}

function setFranchiseListItemSchema(franchise: Franchise, schema: Partial<JsonLdSchema>) {
  schema['@type'] = ['ItemList', 'Article'];
  schema.image = franchise.image?.mediaItemUrl;
  schema.keywords = franchise.keywords;
  schema.articleSection = 'Uncategorized';
  schema.author = setAuthorSchema(franchise.authors);
  return schema;
}

function setFranchiseListItemCompanySchema(franchiseCompany: FranchiseListItem, schema: Partial<JsonLdSchema>) {
  schema['@type'] = ['NewsArticle'];
  schema.thumbnailUrl = franchiseCompany.image?.mediaItemUrl;
  schema.image = franchiseCompany.image?.mediaItemUrl;
  schema.author = setAuthorSchema(franchiseCompany.authors);
  schema.keywords = franchiseCompany.title;
  schema.articleSection = 'Uncategorized';

  return schema;
}

function setPageSchema(
  {
    authors,
    dateGmt,
    databaseId,
    description,
    image,
    link,
    modifiedGmt,
    primarySection,
    tagNames,
    title,
    thumbnailUrl,
    pageType,
  }: Page,
  schema: Partial<JsonLdSchema>,
): JsonLdSchema {
  schema.headline = title;
  schema.url = link;
  schema.mainEntityOfPage = link;
  if (modifiedGmt) schema.dateModified = getLocalIsoDateTime(modifiedGmt);
  if (dateGmt) schema.datePublished = getLocalIsoDateTime(dateGmt);
  schema.identifier = databaseId;
  schema.thumbnailUrl = `${thumbnailUrl}?resize=300,300`;
  schema.image = image instanceof Object ? image.mediaItemUrl : (image as string);
  schema.keywords = tagNames;
  if (pageType !== PageType.HOMEPAGE) schema.articleSection = primarySection.name;
  schema.description = description;
  schema.author = setAuthorSchema(authors);

  return schema as JsonLdSchema;
}

function setEvergreenSchema(
  {
    authors,
    dateGmt,
    databaseId,
    description,
    image,
    link,
    modifiedGmt,
    primarySection,
    tagNames,
    title,
    thumbnailUrl,
  }: Page,
  schema: Partial<JsonLdSchema>,
): JsonLdSchema {
  schema['@type'] = 'Article';
  schema.publisher = setPublisherSchema();
  schema.publisher['@type'] = 'Organization';
  schema.headline = title;
  schema.url = link;
  schema.mainEntityOfPage = link;
  schema.dateModified = getLocalIsoDateTime(modifiedGmt);
  schema.datePublished = getLocalIsoDateTime(dateGmt);
  schema.identifier = databaseId;
  schema.thumbnailUrl = thumbnailUrl;
  schema.image = image instanceof Object ? image.mediaItemUrl : (image as string);
  schema.author = setAuthorSchema(authors);
  schema.keywords = tagNames;
  schema.articleSection = primarySection.name;
  schema.description = description;

  return schema as JsonLdSchema;
}

function setTermPageSchema(
  { authors, dateGmt, databaseId, description, image, link, modifiedGmt, tagNames, title, thumbnailUrl }: Page,
  schema: Partial<JsonLdSchema>,
): JsonLdSchema {
  schema['@type'] = 'Article';
  schema.headline = title;
  schema.url = link;
  schema.mainEntityOfPage = link;
  schema.dateModified = getLocalIsoDateTime(modifiedGmt);
  schema.datePublished = getLocalIsoDateTime(dateGmt);
  schema.identifier = databaseId;
  schema.thumbnailUrl = thumbnailUrl;
  schema.image = image instanceof Object ? image.mediaItemUrl : (image as string);
  schema.author = setAuthorSchema(authors);
  schema.keywords = tagNames;
  schema.description = description;

  return schema as JsonLdSchema;
}

function setBreadcrumbsSchema(page: TermPage | Article, hasStnVideo: boolean) {
  const schema: Partial<JsonLdSchema> = {};
  schema['@type'] = 'BreadcrumbList';
  schema['@context'] = stnMetaSchemaContext(hasStnVideo);
  schema.itemListElement = page.breadcrumbs.items.map(
    (item, index) =>
      ({
        '@type': 'ListItem',
        item: undefined === item.link || item.link === '' ? null : `https://fortune.com${item.link}`,
        name: item.label,
        position: index + 1,
      } as BreadcrumbsListElement),
  );
  return schema;
}

function setPostSchema({ postSettings }: Post, schema: Partial<JsonLdSchema>): JsonLdSchema {
  schema.image = postSettings.mediaItem.mediaItemUrl;
  schema.thumbnailUrl = postSettings.mediaItem.mediaItemUrl;

  return schema as JsonLdSchema;
}

function setCollectionPageSchema(page: Hub | SecondaryHomepage, schema: Partial<JsonLdSchema>): JsonLdSchema {
  schema['@type'] = 'CollectionPage';

  const itemListElements = page.posts.map(
    (item, index): ListSchema => ({
      '@type': 'ListItem',
      position: index + 1,
      url: `https://fortune.com${item.titleLink}`,
    }),
  );

  schema.mainEntity = {
    '@type': 'ItemList',
    itemListElement: itemListElements,
  };

  return schema as JsonLdSchema;
}

function setHomePageSchema(
  { databaseId, description, image, link, tagNames, title, thumbnailUrl, content }: Homepage,
  schema: Partial<JsonLdSchema>,
): JsonLdSchema {
  schema['@type'] = 'WebPage';
  schema.publisher = setPublisherSchema();
  schema.headline = title;
  schema.url = link;
  schema.mainEntityOfPage = link;
  schema.identifier = databaseId;
  schema.thumbnailUrl = thumbnailUrl;
  schema.image = image;
  schema.keywords = tagNames;
  schema.description = description;

  let itemPos = 0;
  const itemListElements = content.reduce((acc: ListSchema[], cur: any): ListSchema[] => {
    const { props } = cur;
    if (props.articles && Array.isArray(props.articles) && props.articles.length > 0) {
      const listItems = props.articles.map((item: any) => {
        itemPos += 1;
        return {
          '@type': 'ListItem',
          position: itemPos,
          url: item.titleLink.includes('fortune.com') ? item.titleLink : `https://fortune.com${item.titleLink}`,
        };
      });
      return [...acc, ...listItems];
    }
    return acc;
  }, []);

  schema.mainEntity = {
    '@type': 'ItemList',
    itemListElement: itemListElements,
  };
  return schema as JsonLdSchema;
}

function setStaticPageSchema({ contentType }: SimplePage, schema: Partial<JsonLdSchema>): JsonLdSchema {
  delete schema.author;
  switch (contentType) {
    case 'aboutpage':
      schema['@type'] = 'AboutPage';
      break;
    case 'article':
      schema['@type'] = 'Article';
      break;
    default:
      schema['@type'] = 'WebPage';
      break;
  }
  schema.publisher = setPublisherSchema();

  return schema as JsonLdSchema;
}

function setSiteLinksSearchBoxSchema(hasStnVideo: boolean) {
  const schema = {
    '@context': stnMetaSchemaContext(hasStnVideo),
    '@type': 'WebSite',
    potentialAction: {
      '@type': 'SearchAction',
      'query-input': 'required name=search_term_string',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: 'https://fortune.com/advanced-search/?query={search_term_string}',
      },
    },
    url: 'https://fortune.com/',
  };
  return schema as SiteLinksSearchBoxSchema;
}

function setPersonSchema({ person }: PersonPage, schema: Partial<JsonLdSchema>): JsonLdSchema {
  delete schema.publisher;
  schema['@type'] = 'Person';
  schema.image = person.image;
  schema.jobTitle = person.position;
  schema.name = person.name;
  schema.alumniOf = person.education;

  return schema as JsonLdSchema;
}

function setEduRankingSchema(ranking: EduRankingPageMeta, schema: Partial<JsonLdSchema>) {
  schema['@type'] = ['ItemList', 'Article'];
  schema.image = ranking.image as string;
  schema.keywords = ranking.keywords;
  schema.articleSection = 'Uncategorized';
  schema.author = ranking.authors && ranking.authors.length > 0 ? setAuthorSchema(ranking.authors) : [];
  return schema;
}

function setEduRankingListSchema(listPage: EduRankingPageMeta) {
  const type = 'School';
  if (!listPage?.lists || listPage?.lists.length === 0) return [];
  const schema = listPage.lists
    .map((entry) =>
      entry.items.map((item) => ({
        '@type': 'ListItem',
        item: {
          '@id': item.itemLink,
          '@type': type,
          description: item.description || '',
          name: item.name,
        },
        position: item.rank,
      })))
    .flat();
  return schema;
}

export function setJsonLdSchema(page: Page): JsonLdSchema | JsonLdSchema[] {
  let schema: Partial<JsonLdSchema> | Partial<JsonLdSchema>[] = {};
  let articleSchema: Partial<JsonLdSchema> = {};
  let breadcrumbsSchema: Partial<JsonLdSchema> = {};
  let siteLinksSearchBoxSchema: Partial<JsonLdSchema> = {};
  let hasStnVideo = false;
  if (page.relatedVideoType === 'stn_video_media' || page.featuredMediaType === 'stn_video_media') {
    hasStnVideo = true;
  }

  schema = setGlobalSchema(schema, hasStnVideo);

  if (hasStnVideo) {
    schema = setDefaultStnMetaSchema(schema);
  }

  switch (page.pageType) {
    case PageType.MAIN_HOMEPAGE:
      schema = setHomePageSchema(page as Homepage, schema);
      siteLinksSearchBoxSchema = setSiteLinksSearchBoxSchema(hasStnVideo);
      schema = [schema, siteLinksSearchBoxSchema];
      break;
    case PageType.HOMEPAGE:
      schema = setPageSchema(page, schema);
      schema = setCollectionPageSchema(page as SecondaryHomepage, schema);
      break;
    case PageType.AUTHOR:
      schema = setAuthorPageSchema(page as AuthorPageData, schema as Partial<AuthorPageSchema>);
      break;
    case PageType.LONGFORM:
    case PageType.ARTICLE:
      schema = setPageSchema(page, schema);
      schema = setPostSchema(page as Post, schema);
      break;
    case PageType.TAG:
    case PageType.SECTION:
    case PageType.LATEST:
    case PageType.TOPIC:
    case PageType.PACKAGE:
    case PageType.EDU_CATEGORY:
      schema = setPageSchema(page, schema);
      schema = setCollectionPageSchema(page as Hub, schema);
      break;
    case PageType.GUIDE:
      articleSchema = setTermPageSchema(page, schema);
      breadcrumbsSchema = setBreadcrumbsSchema(page as TermPage, hasStnVideo);
      schema = [articleSchema, breadcrumbsSchema];
      break;
    case PageType.COMPANY:
      schema = setCompanySchema(page as Company, schema, hasStnVideo);
      break;
    case PageType.FRANCHISE_LIST:
      schema = setPageSchema(page, schema);
      setFranchiseListItemSchema(page as Franchise, schema);
      schema.itemListElement = setItemListSchema(page as FranchisePage);
      setDefaultStnMetaSchema(schema);
      break;
    case PageType.EDU_SCHOOL:
    case PageType.FRANCHISE_LIST_ITEM:
      schema['@type'] = 'Article';
      schema = setPageSchema(page, schema);
      break;
    case PageType.FRANCHISE_LIST_ITEM_COMPANY:
      schema = setPageSchema(page, schema);
      setFranchiseListItemCompanySchema(page as FranchiseListItem, schema);
      break;
    case PageType.EVERGREEN:
      articleSchema = setEvergreenSchema(page, schema);
      breadcrumbsSchema = setBreadcrumbsSchema(page as Article, hasStnVideo);
      schema = [articleSchema, breadcrumbsSchema];
      break;
    case PageType.STATIC_PAGE:
      schema = setPageSchema(page, schema);
      schema = setStaticPageSchema(page as SimplePage, schema);
      break;
    case PageType.MAGAZINE:
      schema['@type'] = 'CollectionPage';
      schema = setPageSchema(page, schema);
      break;
    case PageType.PERSON:
      schema = setPersonSchema(page as PersonPage, schema);
      break;
    case PageType.EDU_RANKING_LIST:
    case PageType.EDU_CHILD_RANKING_LIST:
      schema = setPageSchema(page, schema);
      setEduRankingSchema(page as EduRankingPageMeta, schema);
      schema.itemListElement = setEduRankingListSchema(page as EduRankingPageMeta);
      break;
    default:
      schema = setPageSchema(page, schema);
      break;
  }

  return schema as JsonLdSchema;
}
